@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Rubik:ital,wght@0,300;1,300&display=swap');

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.spinner {
  width: 20px;
  height: 20px;
  border: 4px solid rgba(250, 250, 250, 0.316);
  border-left-color: rgb(222, 222, 222);
  border-radius: 50%;
  animation: spinner 1s linear infinite;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.bg-mainBackground {
  height: 100vh;
}
